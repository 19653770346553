import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles"
import {
  CssBaseline,
  Typography,
  Container,
  Box,
  Link as StyleLink,
  AppBar,
  Toolbar,
  Button,
} from "@material-ui/core"

const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      light: "#77a0b0", // 119 160 176
      main: "#55899d", // 85 137 157
      dark: "#3b5f6d", // 59 95 109
      contrastText: "#fff",
    },
    secondary: {
      light: "#dca665", // 220 166 101
      main: "#d4903f", // 212 144 63
      dark: "#94642c", // 148 100 44
      contrastText: "#000",
    },
  },
})

const Layout = ({ children }) => {
  const { logo } = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <AppBar color="default">
        <Container maxWidth="lg">
          <Toolbar>
            <Box marginRight={2}>
              <Img fixed={logo.childImageSharp.fixed} />
            </Box>
            <Typography variant="h5" style={{ flexGrow: 1 }}>
              <Box fontWeight="fontWeightLight">Proxiloup</Box>
            </Typography>
            <Button
              color="secondary"
              variant="outlined"
              component="a"
              href="https://app.proxiloup.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              Se connecter
            </Button>
          </Toolbar>
        </Container>
      </AppBar>

      {children}

      <Box paddingY={4} bgcolor={theme.palette.grey[900]}
      
      >
        <Container maxWidth="md">
          <Typography align="center">
            {`Création web 2020 : `}
            <StyleLink
              color="secondary"
              href="https://pro.guslyon.fr"
              target="_blank"
              rel="noreferrer noopener"
            >
              GusLyon.fr
            </StyleLink>
          </Typography>
        </Container>
      </Box>
    </ThemeProvider>
  )
}

export default Layout
